import { Dialog, Fade, Grid, Typography, useTheme } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { DialogProps } from '@material-ui/core/Dialog';
import React, { forwardRef, useCallback } from 'react';
import { useStyles } from './cardapio-canner-comanda-style';
import CloseIcon from '@material-ui/icons/Close';
import { MergedCardapio } from 'api/merged';
import Scanner from './scanner/scanner';

interface DialogScannerComandaProps {
  openned?: boolean;
  loading?: boolean;
  zIndex?: string;
  tamanho?: DialogProps['maxWidth'];
  empresaId: string;
  mesaId: string;
  nomeCliente?: string;
  empresaDados: MergedCardapio | undefined;
  sacolaAberta?: boolean;
  garcomAberto?: boolean;
  statusPedido: boolean;
  closeModal: () => void;
  quandoFechado?: () => void;
  setCarregandoInterno?: (value: boolean) => void;
  handleCodigo?: (codigo: string) => void;
}

export const DialogScannerComanda = ({
  setCarregandoInterno,
  ...props
}: DialogScannerComandaProps) => {

  const classes = useStyles();
  const theme = useTheme()

  const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
  ) {
    return <Fade timeout={500} ref={ref} {...props} />;
  });

  const leituraRealizada = useCallback(async (codigo: string) => {
    if (codigo.length > 3) {
      codigo = codigo.slice(codigo.length - 3, codigo.length)

      if (codigo[0] === '0' && codigo[1] === '0') {
        codigo = codigo.slice(codigo.length - 1, codigo.length)
      }
      else if (codigo[0] === '0') {
        codigo = codigo.slice(codigo.length - 2, codigo.length)
      }
    } else {
      if (codigo[0] === '0' && codigo[1] === '0') {
        codigo = codigo.slice(codigo.length - 1, codigo.length)
      }
      else if (codigo[0] === '0') {
        codigo = codigo.slice(codigo.length - 2, codigo.length)
      }
    }
    if (props.handleCodigo) {
      props.handleCodigo(codigo)
      props.closeModal()
    }
  }, [props]);

  return (
    <>
      {props.openned &&
        <Dialog
          maxWidth={props.tamanho || 'md'}
          open={props.openned || false}
          fullWidth
          keepMounted
          className={classes.root}
          disableEscapeKeyDown
          TransitionComponent={Transition}
          ref={(el: any) => {
            if (el && (props.zIndex || '').length > 0) {
              el.style.setProperty('z-index', props.zIndex, 'important');
            }
          }}
        >
          <div style={{ padding: 8 }}>
            <Typography
              style={{
                textAlign: 'center',
                margin: '0 0 24px 0',
                fontWeight: 600,
                background: theme.palette.type === "dark" ? "rgba(255, 255, 255, 0.3)" : 'rgb(0 0 0 / 50%)',
                padding: 12,
                color: '#FFF',
                borderRadius: 8,
              }}
            >
              Passe o QR Code da comanda na marcação
            </Typography>
            <div style={{ borderRadius: '8px', overflowY: 'hidden' }}>
              {props.openned && (
                <Scanner onScan={leituraRealizada} inicialOrSecundario={false} />
              )}
            </div>
            <Grid container style={{ margin: '5px' }}>
              <Grid item xs={12} style={{
                justifyContent: 'center', display: 'flex'
              }}>
                <CloseIcon
                  onClick={() => {
                    props.closeModal()
                  }}
                  style={{ fontSize: 50, marginTop: 16, cursor: 'pointer' }}
                />
              </Grid>
            </Grid>
          </div>
        </Dialog>
      }
    </>
  );
};
