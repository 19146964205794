import { MergedCardapio } from "api/merged";
import {
  Typography,
  Divider,
  Grid,
  Button,
  Drawer,
  useMediaQuery,
  useTheme,
  IconButton,
  DialogContent,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContentText,
  Box,
} from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import classNames from "classnames";
import { useStyles } from "./cardapio-resumo-pedido-styles";
import { ProdutoPedidoCard } from "./components";
import { MeusPedidosIcon } from "utils";
import { localeString } from "utils/locale-string";
import { usePedidos } from "componentes/providers/pedidos-provider";
import { Carregando } from "componentes";
import { isEmpty } from "lodash";
import { PedidoProdutoModel } from "api/cardapio/models/pedido/pedido";
import { EnumTpProduto } from 'api/cardapio/models/produto/mov-simples-produto-model';

export interface CardapioResumoPedidoProps {
  empresaDados: MergedCardapio | undefined;
  empresaId: string;
  mesaId: string;
  garcomAberto: boolean;
  quandoFechado: () => any;
}

export const CardapioResumoPedido = ({
  empresaDados,
  empresaId,
  mesaId,
  garcomAberto,
  quandoFechado,
}: CardapioResumoPedidoProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const variant = 'temporary';
  const anchor = isMobile ? 'bottom' : 'right';
  const [open, setOpen] = useState(false);
  const { carregandoGetPedido } = usePedidos()

  const { pedidoAtual, getPedido } = usePedidos()
  const produtos = useMemo(() => pedidoAtual?.produtos.filter(x => x.indFin) ?? [], [pedidoAtual?.produtos])

  useEffect(() => {
    if (garcomAberto) {
      if (empresaDados?.configuracoes.tipoUsoCardapio === 2) {
        getPedido();
      }
    }
  }, [empresaDados?.configuracoes.tipoUsoCardapio, garcomAberto, getPedido])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const precoFilter = (item: PedidoProdutoModel) => (item.status.codigo === 1 || item.status.codigo === 4) && item.indFin && item.tpProduto.codigo !== EnumTpProduto.Combo

  //Total dos itens da mesa
  const total = useCallback(() => {
    return localeString(pedidoAtual?.produtos.filter(precoFilter).reduce((a, b) => a + b.valorTotal, 0) ?? 0);
  }, [pedidoAtual?.produtos]);

  const totalDesconto = useCallback(() => {
    return localeString(pedidoAtual?.produtos.filter(precoFilter).reduce((a, b) => a + b.valorTotalDesconto, 0) || 0);
  }, [pedidoAtual?.produtos]);

  const totalTaxaServico = useMemo(() =>
    localeString(pedidoAtual?.produtos.filter(precoFilter).reduce((a, b) => a + b.valorTotalServico, 0) || 0)
    , [pedidoAtual?.produtos])

  const colorLogo = useMemo(() => {
    return theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.text.primary;
  }, [theme.palette.primary.main, theme.palette.text.primary, theme.palette.type]);



  const pedidoAgrupado = useMemo(() => {
    return produtos.map((x) => ({
      ...x,
      subItens: (pedidoAtual?.produtos ?? []).filter(y => y.groupId === x.codigoReferencia && x.produtoIdReferencia !== y.produtoIdReferencia)
    }))
  }, [pedidoAtual?.produtos, produtos])

  return (
    <>
      <Drawer
        variant={variant}
        anchor={anchor}
        open={garcomAberto}
        classes={{ paper: classes.drawer }}
        onClose={quandoFechado}
      >

        <Carregando
          className={classes.carregando}
          carregando={carregandoGetPedido}
          modelo="absolute"
          titulo={'Carregando pedido'}
          mensagem={'Aguarde enquanto carregamos seu pedido.'}
        ></Carregando>

        <div className={classes.header}>
          <IconButton
            onClick={quandoFechado}
            style={{ marginBottom: '-4px' }}
          >
            <ArrowBackIosIcon
              style={{ paddingLeft: '8px', fontSize: '32px', color: colorLogo }}
            />
          </IconButton>
          <div style={{ display: "flex", justifyContent: isMobile ? 'flex-end' : 'center', width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: isMobile ? '8px' : '8px', display: 'flex' }}>
                <MeusPedidosIcon tipo='BUTTON' fill={colorLogo} />
              </div>
              <label htmlFor="MeusPedidos" style={{ fontWeight: 'bold', fontSize: '20px', fontStyle: 'revert' }}>MEUS PEDIDOS</label>
            </div>
          </div>
        </div>

        <Divider style={{ marginTop: '1rem' }} />
        {!carregandoGetPedido && isEmpty(pedidoAtual) && isEmpty(pedidoAtual?.produtos) && (
          <Box height={'100%'} width="100%" display={"flex"} textAlign={"center"} alignItems={"center"} justifyContent={"center"}>
            <Typography variant="h5">
              <Box fontWeight={600}>
                Nenhum produto encontrado, faça um novo pedido.
              </Box>
            </Typography>
          </Box>
        )}
        {!carregandoGetPedido && (pedidoAtual?.produtos ?? []).length > 0 && (
          <>
            {(mesaId !== undefined &&
              <Grid container>
                <Grid item xs={6}>
                  <Typography color="textPrimary" className={classes.mesa}>
                    {`Mesa: ${pedidoAtual?.mesaCodigo ?? ''}`}
                  </Typography>
                </Grid>
                {!isEmpty(pedidoAtual?.codigoComanda) && (
                  <Grid item xs={6}>
                    <Typography color="textPrimary" className={classes.comanda}>
                      {`Comanda: ${pedidoAtual?.codigoComanda ?? ''}`}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}
            <Box mt={1}>
              <Divider />
            </Box>

            <div className={classes.scrollable}>
              {pedidoAgrupado?.filter(x => isEmpty(x.adicionalId) && x.indFin).map((item, index) => {
                return (
                  <ProdutoPedidoCard
                    item={item}
                    key={index}
                  />
                );
              })}
            </div>

            <div className={classes.footer}>
              <Divider />
              <Grid container justify="space-between" style={{ marginTop: '1rem' }}>
                <Grid item>
                  {totalDesconto() && (
                    <>
                      <Typography variant="body2" color="textSecondary">
                        Desconto
                      </Typography>
                      <Typography variant="h5" color="textPrimary" className={classes.textoTotais}>
                        {totalDesconto()}
                      </Typography>
                    </>
                  )}
                </Grid>
                <Grid item>
                  {totalTaxaServico && (
                    <>
                      <Typography variant="body2" color="textSecondary">
                        Taxa de serviço
                      </Typography>
                      <Typography variant="h5" color="textPrimary" className={classes.textoTotais}>
                        {totalTaxaServico}
                      </Typography>
                    </>
                  )}
                </Grid>
                <Grid item>
                  <Typography variant="body2" color="textSecondary">
                    Total
                  </Typography>

                  <Typography variant="h5" color="textPrimary" className={classes.textoTotais}>
                    {total()}
                  </Typography>
                </Grid>
              </Grid>
              {false && (
                <Grid container justify="center" className={classes.grdBtns}>
                  <Button
                    variant="contained"
                    size="large"
                    className={classes.btnFooter}
                    onClick={handleClickOpen}
                  >
                    Fechar a conta
                  </Button>
                  <div>
                    <Dialog
                      open={open}
                      keepMounted
                      onClose={quandoFechado}
                      aria-describedby="alert-dialog-slide-description"
                    >
                      <DialogTitle>{"Finalizar Conta"}</DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                          Você deseja chamar o garçom para finalizar a sua conta?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose}>Sim</Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                  <Button
                    variant="contained"
                    size="large"
                    className={classNames(classes.buttonPedir, classes.btnFooter)}
                  >
                    Chamar Garçom
                  </Button>

                </Grid>
              )}
            </div>
          </>
        )}
      </Drawer >
    </>
  );
};